<script setup lang="ts">
/**
 * @author Irakli Kandelaki
 */
import "swiper/css";

/** IMPORT HOOKS */
import { onMounted, ref, nextTick } from "vue";

/** IMPORT COMPONENTS */
const CaretTitle = defineAsyncComponent(
  () => import("@/components/regularComponents/UIComponents/CaretTitle.vue")
);
const Slide = defineAsyncComponent(
  () => import("./parts/ProjectsSliderCard.vue")
);
import { Swiper, SwiperSlide } from "swiper/vue";
/** IMPORT COMPOSABLES */


/** IMPORT STORE */
import { useMainStore } from "@/store/main/index";

/** IMPORT TYPES */
import type { Ref } from "vue";
import type { SecondaryDataInterface } from "@/types/ContentType";

/** PROPS */
defineProps<{
  contentData: SecondaryDataInterface | undefined;
}>();

/** REACTIVE DATA */
const sliderEl: Ref<HTMLElement | undefined> = ref();
const isTrigger: Ref<boolean> = ref(false);

/** INIT MAIN STORE */
const mainStore = useMainStore();

const spaceBetween = computed(() => {
  if (mainStore.windowWidth >= 3840) {
    return 72
  } else if (mainStore.windowWidth <= 1247) {
    return 32
  } else if (mainStore.windowWidth <= 1919) {
    return 40
  } else if (mainStore.windowWidth <= 2559) {
    return 36
  } else if (mainStore.windowWidth <= 3839) {
    return 48
  }
});

onMounted(async () => {
  await nextTick();

  setTimeout(() => {
    isTrigger.value = true;
  }, 500);
});
</script>

<template>
  <section
    class="projects-slider bg-white pos-rel"
    ref="sliderEl"
    :class="{ 'is-inview': isTrigger }"
  >
    <h2 class="projects-slider__title pos-abs uppercase">
      {{ $t("projects") }}
    </h2>

    <div class="container pos-abs projects-slider__grid-wrapper">
      <div class="projects-slider__grid d-grid">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    
    <div class="projects-slider__header container pos-rel">
      <CaretTitle
        :title="contentData?.conf?.header?.title"
        :noGrid="mainStore.windowWidth >= 984"
      />
    </div>

    <div class="container">
      <Swiper
        slidesPerView="auto"
        :spaceBetween="spaceBetween"
        :grabCursor="true"
        :updateOnResize="true"
      >
        <SwiperSlide v-for="(slide, index) in contentData?.data?.list" :key="index">
          <Slide :style="{ 'transition-delay': 0.1 * index + 's' }" :slide="slide" />
        </SwiperSlide>
      </Swiper>
    </div>
  </section>
</template>

<style lang="scss">
.projects-slider {
  width: 100%;
  padding-bottom: 190px;
  min-height: 100vh;
  // bottom: 0;
  z-index: 3;
  .swiper {
    overflow: visible;
  }
  .swiper-slide {
    width: 956px;
    height: 1536px;

    @include maxq(desktop-2k) {
      width: 638px;
      height: 1024px;
    }
    @include maxq(desktop-lg) {
      width: 478px;
      height: 768px;
    }
    @include maxq(tablet) {
      width: 344px;
      height: 552px;
    }
    @include maxq(mobile) {
      width: 269px;
      height: 432px;
    }

    a {
      width: 100%;
      height: 100%;
    }
  }
  @include maxq(desktop) {
    height: auto;
  }
  &__inner {
    width: 100%;
    // padding-left: 100%;
    padding-left: 414px;
    @include easeIn(1650, all);

    @include maxq(desktop-2k) {
      padding-left: 274px;
    }
    @include maxq(desktop-lg) {
      padding-left: 207px;
    }
    @include maxq(desktop) {
      padding-left: 104px;
    }
    @include maxq(tablet) {
      padding-left: 0px;
    }
    &.not-in-view {
      transform: translateX(calc(100% - 414px));

      @include maxq(desktop-2k) {
        transform: translateX(calc(100% - 274px));
      }
      @include maxq(desktop-lg) {
        transform: translateX(calc(100% - 207px));
      }
      @include maxq(desktop) {
        transform: translateX(calc(100% - 104px));
      }
      @include maxq(tablet) {
        transform: translateX(0);
      }
    }
  }

  &.is-inview {
    .projects-slider-card {
      transform: translateX(0);
      opacity: 1;
    }
    &.not-in-view {
      .projects-slider__inner {
        transform: translateX(0);
        @include easeOut(1650, all);
      }
    }
  }

  .container:nth-child(1) {
    top: 0;
    left: 0;
    height: 100%;
  }

  &__grid {
    grid-template-columns: 50% 19.53125% 19.53125% 10.78125%;
    height: 100%;
    width: 100%;

    div {
      border-right: $brightGray 1px solid;

      &:nth-child(1) {
        border-left: $brightGray 1px solid;
      }
    }
    &-wrapper {
      height: 100%;
      border-bottom: $brightGray 1px solid;
    }

    @include maxq(desktop-2k) {
      grid-template-columns: 50% 39.22764227642276% 10.7723577236%;
    }

    @include maxq(desktop-lg) {
      grid-template-columns: 50% 39.22764227642276% 10.7723577236%;
    }

    @include maxq(desktop) {
      grid-template-columns: 50% 21.25% 21.25%;
    }

    @include maxq(tablet) {
      grid-template-columns: 50% 39.22764227642276%;
    }

    @include maxq(mobile) {
      grid-template-columns: 100%;
      div {
        border: none;
        &:nth-child(1) {
          border: none;
        }
      }
    }
  }

  &__container {
    width: fit-content;
  }

  &__title {
    font-size: 708px;
    line-height: 863px;

    top: 343px;
    left: 44.40104166666667%;
    color: #f3f3f3;
    @include weight(900);

    @include maxq(desktop-2k) {
      top: 225px;
      left: 43.75%;
      font-size: 476px;
      line-height: 580px;
    }

    @include maxq(desktop-lg) {
      top: 166px;
      left: 43.645833333333336%;
      font-size: 364px;
      line-height: 443px;
    }

    @include maxq(desktop) {
      left: 50.55555555555556%;
    }

    @include maxq(tablet) {
      top: 191px;
      left: 51.21951219512195%;
      font-size: 196px;
      line-height: 239px;
    }

    @include maxq(mobile) {
      display: none;
    }
  }

  @include maxq(desktop-2k) {
    padding-bottom: 107px;
  }
  @include maxq(desktop-lg) {
    padding-bottom: 81px;
  }
  @include maxq(tablet) {
    min-height: 795px;
    &__inner {
      height: 100%;
    }
  }
  @include maxq(mobile) {
    position: relative !important;
    min-height: initial;

    padding-bottom: 64px;

    height: auto;
  }

  .caret-title {
    border-right: none;

    &::after {
      content: none;
    }
  }
}
</style>
